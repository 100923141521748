import { Stack } from '@mui/material';
import Header from './Header';
import SearchField from './SearchField';
import SuggestedClaimButtons from './SuggestedClaims';

const PlaygroundV2Page = () => {
  return (
    <Stack mt={4}>
      <Header />
      <SearchField />
      <SuggestedClaimButtons />
    </Stack>
  );
};

export default PlaygroundV2Page;
