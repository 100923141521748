import axios, { AxiosResponse } from 'axios';
import authConfig from '../../authConfig.json';

let BASE_URL = authConfig.apiBaseURL.production;

const TIMEOUT = 500000;

switch (process.env.DEPLOYMENT) {
  case 'development':
    BASE_URL = authConfig.apiBaseURL.development;
    break;
  case 'testing':
    BASE_URL = authConfig.apiBaseURL.testing;
    break;
  case 'conference':
    BASE_URL = authConfig.apiBaseURL.conference;
    break;
  case 'production':
    BASE_URL = authConfig.apiBaseURL.production;
    break;
  default:
    break;
}

console.log(BASE_URL);

let token = '';

/**
 * Creates a new axios instance
 *
 * @param {string} newUrl the new api url
 * @param {string} newToken the new auth token
 */
export function updateAPI(newUrl?: string, newToken?: string): void {
  BASE_URL = newUrl ?? BASE_URL;
  token = newToken ?? token;
  factiverseApi = axios.create({
    timeout: TIMEOUT,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    baseURL: BASE_URL,
  });
}

export let factiverseApi = axios.create({
  timeout: TIMEOUT,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
  baseURL: BASE_URL,
});

/* STANCE DETECTION */

// interface Evidence {
//   authors: Array<string>;
//   doc: string;
//   domain: string;
//   domainName: string;
//   evidenceSnippet: string;
//   keywords: Array<string>;
//   labelDescription: string;
//   predictedLabel: 0 | 1;
//   publishDate: string;
//   searchEngine: string;
//   snippet: string;
//   softmaxScore: [number, number];
//   title: string;
//   url: string;
// }

interface StanceDetectionResponse {
  claim: string;
  _id: string;
  evidence: Array<Source>;
  finalPrediction: number;
  finalScore: number;
  summary: Array<string>;
  fix: string;
}

/**
 * Send a stance detection request to the api
 *
 * @param {string} claim The claim to be checked
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @param {string} resolvedClaim A more precise version of the claim
 * @return {object} The api response
 */
export function stanceDetectionRequest(
  claim: string,
  logging: boolean,
  resolvedClaim?: string
): Promise<AxiosResponse<StanceDetectionResponse>> {
  return factiverseApi.post('stance_detection', {
    claim: claim,
    lang: '',
    logging: logging,
    resolved_claim: resolvedClaim,
    search_engines: [
      'Bing Search',
      'Google Claim',
      'Google',
      'Wikipedia',
      'Semantic Scholar',
      'ElasticSearch',
      'You.com',
    ],
  });
}

/* CLAIM DETECTION */

interface ClaimDetectionResponse {
  detectedClaims: Array<Claim>;
}

/**
 * Send a claim detection request to the api
 *
 * @param {string} text The text to be checked for claims
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function claimDetectionRequest(
  text: string,
  logging: boolean
): Promise<AxiosResponse<ClaimDetectionResponse>> {
  return factiverseApi.post('claim_detection', {
    text: text,
    lang: '',
    logging: logging,
    // TODO: set claimScoreThreshold depending on language, 0.75 default for English but might have to be lower for others
  });
}

/**
 * A unique unit, e.g. a person, organisation, company, etc. which is independent from its string description
 * @example "The president", "US president", "Washington", etc. are all the same entity depending on context
 */
interface Entity {
  _id: string;
  /**  */
  entity_type: string;
  kbs_refs: Array<KbRefs>;
}

/**
 * Detailed info on an entity from a certain knowledge base
 */
interface KbRefs {
  description: string;
  domain: string;
  entity_match: string;
  image_license: string;
  image_url: string;
  kb_id: string;
  license: string;
  page_url: string;
  title: string;
}

/**
 * An occurence of an entity within a text
 */
interface Spot {
  start: number;
  stop: number;
  text: string;
  description: string;
  url?: string;
  domain?: string;
  checked?: boolean;
  entity: Entity;
}

interface MicrofactsResponse {
  _id: string;
  access_token: string;
  lang: string;
  collection: string;
  spots: Array<Spot>;
}

/**
 * Send a microfact request to the api
 *
 * @param {string} text The claim to be checked
 * @param {string} language The language of the microfact
 * @return {object} The api response
 */
export function microfactsRequest(
  text: string,
  language: string
): Promise<AxiosResponse<MicrofactsResponse>> {
  return factiverseApi.post('microfacts', {
    text: text,
    lang: language,
  });
}

/**
 * GET Microfacts from /microfacts
 *
 * @param {string} lang The language
 * @param  {string} id The ID
 * @return {object} The entity-linking data
 */
export function getArticle(
  lang: string,
  id: string
): Promise<AxiosResponse<MicrofactsResponse>> {
  return factiverseApi.get(`microfacts/?id=${id}&lang=${lang}`);
}

/* TOPICS */

interface ClaimsSearchPostRequest {
  reverseSortPubDate: boolean;
  lang: string;
  logging: boolean;
  query: string;
  searchEngine: Array<string>;
  fromDate?: string;
  toDate?: string;
  size?: number;
}
interface ClaimSearchPostResponse {
  searchResults: Array<SearchResult>;
}

/**
 * Requests manual fact checks and word cloud for a given topic
 *

 * @param {boolean} reverseSortPubDate Determines the sorting of results
 * @param {string} language The language of the query
 * @param {boolean} logging True if the server should log this request
 * @param {string} query The search term
 * @param {Object} searchEngine The search engine to find results with
 * @param {string} fromDate The start of the date range of fact checks
 * @param {string} toDate The end of the date range of fact checks
 * @param {number} size How many results are requested
 * @return {Object} A wordcloud and related manual fact checks
 */
export function claimSearchPostRequest(
  reverseSortPubDate: boolean,
  language: string,
  logging: boolean,
  query: string,
  searchEngine: Array<string>,
  fromDate?: string,
  toDate?: string,
  size?: number
): Promise<AxiosResponse<ClaimSearchPostResponse>> {
  let request: ClaimsSearchPostRequest = {
    reverseSortPubDate: reverseSortPubDate,
    lang: language,
    logging: logging,
    query: query,
    searchEngine: searchEngine,
  };
  if (size !== undefined) request = { ...request, size: size };
  if (fromDate !== undefined && toDate !== undefined)
    request = { ...request, fromDate: fromDate, toDate: toDate };
  return factiverseApi.post('claim_search', request);
}

/**
 * For a given time frame a fact checking site will publish a number of articles.
 *
 * @property {string} lang The language of the source
 * @property {number} lang The number of articles published by the source
 * @property {string} lang The name of the source
 *
 * @example {'lang': 'en', 'number': 5, 'source': 'politifact'}
 */
export interface SourceStatistic {
  lang: string;
  number: number;
  source: string;
}

/**
 * The response to a request to GET /claim_search
 *
 * @returns Stats on available languages and number of published articles by source
 */
interface ClaimSearchGetResponse {
  sourceStatistics: Array<SourceStatistic>;
  supportedLanguages: Array<SupportedLanguage>;
  totalFactChecks?: string;
}

/**
 * Requests manual fact checks and word cloud for a given topic
 *
 * @param {string} fromDate The start of the date range of fact checks in yyyy-MM-dd format
 * @param {string} toDate The end of the date range of fact checks in yyyy-MM-dd format
 * @return {Object} Stats on available languages and number of published articles by source
 */
export function claimSearchGetRequest(
  fromDate?: string,
  toDate?: string
): Promise<AxiosResponse<ClaimSearchGetResponse>> {
  let parameters = '';
  if (fromDate !== undefined && toDate !== undefined)
    parameters = '?fromDate=' + fromDate + '&toDate=' + toDate;
  return factiverseApi.get('statistics/claim_search' + parameters);
}

/* FEEDBACK */

interface FeedbackResponse {
  status: boolean;
}

/**
 * Send feedback on claim credibility to the api
 *
 * @param {string} claim The claim to give feedback on
 * @param {string} email The user's email address
 * @param {boolean} userAgrees True if the user confirms the credibility assessment
 * @param {language} language The language of the claim
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function feedbackCredibilityRequest(
  claim: string,
  email: string,
  userAgrees: boolean,
  language: string,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  return factiverseApi.post('feedback', {
    feedbackType: 'claimStance',
    payload: {
      claim: claim,
      email: email,
      userAgrees: userAgrees,
      lang: language,
      logging: logging,
    },
  });
}

/**
 * Send feedback on source relevance to the api
 *
 * @param {string} claim The claim to which the source belongs to
 * @param {string} email The user's email address
 * @param {boolean} userIsRelevant True if the user confirms the relevance of the source
 * @param {language} language The language of the claim
 * @param {object} evidence The source
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function feedbackSourceRelevanceRequest(
  claim: string,
  email: string,
  userIsRelevant: boolean,
  language: string,
  evidence: Source,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  return factiverseApi.post('feedback', {
    feedbackType: 'sourceRelevance',
    payload: {
      claim: claim,
      email: email,
      userAgrees: userIsRelevant,
      lang: language,
      evidence: evidence,
      logging: logging,
    },
  });
}

/**
 * Send feedback on source support to the api
 *
 * @param {string} claim The claim to which the source belongs to
 * @param {string} email The user's email address
 * @param {boolean} userAgrees True if the user confirms the support of the source
 * @param {language} language The language of the claim
 * @param {object} evidence The source
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {object} The api response
 */
export function feedbackSourceSupportRequest(
  claim: string,
  email: string,
  userAgrees: boolean,
  language: string,
  evidence: Source,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  return factiverseApi.post('feedback', {
    feedbackType: 'sourceStance',
    payload: {
      claim: claim,
      email: email,
      userAgrees: userAgrees,
      lang: language,
      evidence: evidence,
      logging: logging,
    },
  });
}

interface UserOnboardingQuestionsResponse {
  _id: string;
  collection: string;
}

/**
 * Send user onboarding answers to the api
 *
 * @param {string} selectedWritingMatter User selected writing matter
 * @param {string} selectedTypeOfWriting User selected type of writing
 * @param {string} selectedPlace User selected place heard from
 * @param {string} selectedIndustry User selected place heard from
 * @return {object} The api response
 */
export function userOnboardingQuestionsRequest(
  selectedWritingMatter,
  selectedTypeOfWriting,
  selectedPlace,
  selectedIndustry
): Promise<AxiosResponse<UserOnboardingQuestionsResponse>> {
  return factiverseApi.post('user', {
    onboarding_questions: [
      { answer: selectedWritingMatter },
      { answer: selectedTypeOfWriting },
      { answer: selectedPlace },
      { answer: selectedIndustry },
    ],
  });
}

interface GetUserResponse {
  allow_tracking: boolean;
  seen_tutorial: boolean;
  onboarding_questions: Array<OnboardingQuestion>;
}

/**
 * get user data
 *
 * @return {object} The api response
 */
export function getUserRequest(): Promise<AxiosResponse<GetUserResponse>> {
  return factiverseApi.get('user');
}

interface PostUserResponse {
  detail: string;
}

/**
 * post user data
 *
 * @param {boolean} allowTracking User allow tracking
 * @param {boolean} seenTutorial User seen tutorial
 * @param {array} onboardingQuestions User selected place heard from
 * @return {object} The api response
 */
export function postUserRequest(
  allowTracking: boolean,
  seenTutorial: boolean,
  onboardingQuestions: Array<OnboardingQuestion>
): Promise<AxiosResponse<PostUserResponse>> {
  return factiverseApi.post('user', {
    allow_tracking: allowTracking,
    seen_tutorial: seenTutorial,
    onboarding_questions: onboardingQuestions,
  });
}

interface PutUserResponse {
  _id: string;
  collection: string;
}

/**
 * put user data
 *
 * @param {boolean} allowTracking User allow tracking
 * @param {boolean} seenTutorial User seen tutorial
 * @param {object} onboardingQuestions User selected place heard from
 * @return {array} The api response
 */
export function putUserRequest(
  allowTracking?: boolean,
  seenTutorial?: boolean,
  onboardingQuestions?: Array<OnboardingQuestion>
): Promise<AxiosResponse<PutUserResponse>> {
  return factiverseApi.put('user', {
    allow_tracking: allowTracking,
    seen_tutorial: seenTutorial,
    onboarding_questions: onboardingQuestions,
  });
}

export function deleteUserRequest() {
  return factiverseApi.delete('user');
}
