import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import authConfig from '../../authConfig.json';

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const audience = authConfig.audience;
  const scope = authConfig.scope;

  let domain = authConfig.domain.production;
  let clientId = authConfig.clientId.production;
  let redirectUri = authConfig.redirectUri.development;

  switch (process.env.DEPLOYMENT) {
    case 'development':
      domain = authConfig.domain.development;
      clientId = authConfig.clientId.development;
      redirectUri = authConfig.redirectUri.development;
      break;
    case 'testing':
      domain = authConfig.domain.testing;
      clientId = authConfig.clientId.testing;
      redirectUri = authConfig.redirectUri.testing;
      break;
    case 'conference':
      domain = authConfig.domain.conference;
      clientId = authConfig.clientId.conference;
      redirectUri = authConfig.redirectUri.conference;
      break;
    case 'production':
      domain = authConfig.domain.production;
      clientId = authConfig.clientId.production;
      redirectUri = authConfig.redirectUri.production;
      break;
    default:
      break;
  }

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        scope: scope,
        audience: audience,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
