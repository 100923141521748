import { useDispatch } from 'react-redux';
import { putUserRequest } from 'utils/API/api.service';
import { useAppSelector } from '../core/store/hooks';
import {
  setAllowTracking,
  setOnboardingQuestions,
  setSeenTutorial,
} from '../core/store/userSlice';

export const useSettings = () => {
  const dispatch = useDispatch();
  const { allowTracking, seenTutorial, onboardingQuestions } = useAppSelector(
    (state) => state.user
  );

  const handleAllowTrackingChange = (newAllowTracking: boolean) => {
    dispatch(setAllowTracking(newAllowTracking));
    putUserRequest(newAllowTracking, seenTutorial, onboardingQuestions);
  };

  const handleSeenTutorialChange = (newSeenTutorial: boolean) => {
    dispatch(setSeenTutorial(newSeenTutorial));
    putUserRequest(allowTracking, newSeenTutorial, onboardingQuestions);
  };

  const handleOnboardingQuestionsChange = (
    newOnboardingQuestions: OnboardingQuestion[]
  ) => {
    dispatch(setOnboardingQuestions(newOnboardingQuestions));
    putUserRequest(allowTracking, seenTutorial, newOnboardingQuestions);
  };

  const handleSettingsChange = (
    newAllowTracking: boolean,
    newSeenTutorial: boolean,
    newOnboardingQuestions: OnboardingQuestion[]
  ) => {
    dispatch(setAllowTracking(newAllowTracking));
    dispatch(setSeenTutorial(newSeenTutorial));
    dispatch(setOnboardingQuestions(newOnboardingQuestions));
    putUserRequest(newAllowTracking, newSeenTutorial, newOnboardingQuestions);
  };

  return {
    allowTracking,
    seenTutorial,
    onboardingQuestions,
    handleAllowTrackingChange,
    handleSeenTutorialChange,
    handleOnboardingQuestionsChange,
    handleSettingsChange,
  };
};

export default useSettings;
