import { useAuth0 } from '@auth0/auth0-react';
import { ReplayOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Sources from 'components/Sources';
import useSettings from 'hooks/useSettings';
import useUserMetaData from 'hooks/useUserMetaData';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  feedbackSourceRelevanceRequest,
  feedbackSourceSupportRequest,
} from 'utils/API/api.service';
import { showError } from 'utils/SnackbarUtils';
import { deleteClaim, updateClaim } from 'core/store/claimsSlice';
import { ErrorMsg } from 'pages/HomePage';
import ClaimSkeleton from '../ClaimSkeleton';
import ClaimLabel from './components/ClaimLabel';
import MissingSources from './components/MissingSources';

interface ClaimProps {
  claim: Claim;
  sourceFilter: SourceFilter;
}

const Claim: React.FC<ClaimProps> = ({ claim, sourceFilter }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { noSubscription } = useUserMetaData();
  const { allowTracking } = useSettings();

  const onFeedbackSourceSupport: OnFeedbackSourceSupport = useCallback(
    (claim: Claim, source: Source, userAgrees: boolean) => {
      feedbackSourceSupportRequest(
        claim.claim,
        user?.email ?? '',
        userAgrees,
        claim?.language ?? 'en',
        source,
        allowTracking
      ).catch((error) => {
        console.error(
          'Error in onFeedbackSourceSupport: ' +
            error.response +
            ' claim: ' +
            claim
        );
        showError(ErrorMsg.NoServerResp);
      });
    },
    [allowTracking, user?.email]
  );

  const onFeedbackSourceRelevance: OnFeedbackSourceRelevance = useCallback(
    (claim: Claim, source: Source, userAgrees: boolean) => {
      feedbackSourceRelevanceRequest(
        claim.claim,
        user?.email ?? '',
        userAgrees,
        claim?.language ?? 'en',
        source,
        allowTracking
      ).catch((error) => {
        console.error(
          'Error in onFeedbackSourceRelevance: ' +
            error.response +
            ' claim: ' +
            claim
        );
        showError(ErrorMsg.NoServerResp);
      });
    },
    [allowTracking, user?.email]
  );

  return (
    <Box px={4} py={2}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={1}
        >
          <ClaimLabel label={claim.claim} />
          <Tooltip title="Remove claim">
            <IconButton
              size="small"
              onClick={() => dispatch(deleteClaim(claim))}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        {process.env.DEPLOYMENT !== 'production' &&
          claim.summary !== undefined && (
            <>
              <Typography fontStyle="italic">Results summary:</Typography>
              {(() => {
                try {
                  return claim.summary.map((item, index) => (
                    <Typography key={index}>- {item}</Typography>
                  ));
                } catch (error) {
                  return <Typography>{claim.summary}</Typography>;
                }
              })()}
            </>
          )}
        {process.env.DEPLOYMENT === 'conference' && claim.fix !== undefined && (
          <>
            <Typography fontStyle="italic">Recommended fix:</Typography>
            <Typography>{claim.fix}</Typography>
          </>
        )}
        {claim.isBeingChecked ? (
          <ClaimSkeleton />
        ) : claim.evidence ? (
          <Stack spacing={2}>
            <Sources
              claim={claim}
              sources={claim.evidence}
              sourceFilter={sourceFilter}
              onFeedbackSourceSupport={onFeedbackSourceSupport}
              onFeedbackSourceRelevance={onFeedbackSourceRelevance}
            />
          </Stack>
        ) : (
          !noSubscription && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton animation={false} height="3em" width="100%" />
              <Tooltip title="Retry request">
                <IconButton
                  onClick={() => {
                    dispatch(updateClaim(claim));
                  }}
                  size="small"
                >
                  <ReplayOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        )}
      </Stack>
      <MissingSources claim={claim} />
    </Box>
  );
};

export default Claim;
