import DiamondIcon from '@mui/icons-material/Diamond';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { setSearchValue } from 'core/store/searchSlice';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { isTooLongText } from './helpers';

const SearchField = () => {
  const dispatch = useDispatch();
  const searchValue = useAppSelector((state) => state.search.searchValue);

  return (
    <Stack mt={4}>
      <TextField
        id="outlined-basic"
        placeholder="Type or paste your text here"
        variant="outlined"
        sx={{
          background: 'white',
          '& fieldset': { border: 'none' },
          borderTopRightRadius: '12px',
          borderTopLeftRadius: '12px',
        }}
        value={searchValue}
        multiline
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          dispatch(setSearchValue(e.target.value))
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                disabled={isTooLongText(searchValue)}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          background: 'white',
          borderBottomRightRadius: '12px',
          borderBottomLeftRadius: '12px',
          borderTop: '1px solid #E8EAEB',
          paddingY: '12px',
          paddingX: '30px',
        }}
      >
        <Typography variant="subtitle2" sx={{ color: '#878787', pr: 1 }}>
          {isTooLongText(searchValue)
            ? 'Text is too long, upgrade to analyze'
            : 'Free version 30 words'}
        </Typography>
        <IconButton
          sx={{
            background: '#feedb4',
            border: '1px solid #f8c406',
            width: '1.5rem',
            height: '1.5rem',
          }}
        >
          <DiamondIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default SearchField;
