import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import { HighlightAs } from 'components/Editor';
import EditorSide from 'components/EditorSide';
import Login from 'components/Login';
import ResultsSide from 'components/ResultsSide';
import {
  setClaims,
  setNoClaimsFound,
  updateClaim,
} from 'core/store/claimsSlice';
import { useAppSelector } from 'core/store/hooks';
import useSettings from 'hooks/useSettings';
import useUserMetaData from 'hooks/useUserMetaData';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Editor as TinyMCEEditor } from 'tinymce';
import { stanceDetectionRequest, updateAPI } from 'utils/API/api.service';
import { showError } from 'utils/SnackbarUtils';
import {
  clearHighlighting,
  getPosition,
  highlight,
} from 'utils/TinyMCE/TinyMCEHelper';
import TrialInfoMessage from './components/TrialInfoMessage';

export enum ErrorMsg {
  NoServerResp = 'We’re sorry! There seems to be an issue with your request. Please bear with us, most issues should be resolved soon.',
  ServerTimeout = 'Sorry, your request is taking longer than usual! Please try again in a bit or get in touch if the issue persists (info@factiverse.no).',
  StanceDetection422 = 'The text provided is malformed. Try changing the text or check out the example texts for inspiration.',
  StanceDetection422Lang = 'The language we detected is not supported. We are working on adding more languages. Please Check the FAQ for all supported languages.',
  ClaimDetection422 = 'The data provided is malformed. Try changing the text or check out the example texts for inspiration.',
  ClaimDetection422Lang = 'The language we detected is not supported. We are working on adding more languages. Please Check the FAQ for all supported languages.',
}

/**
 * This is the main page and langing page for recurring users, with the text editor and all Factiverse features.
 *
 * @return {React.FC} The page component
 */
const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAuthenticated } = useAuth0();
  const [editor, setEditor] = useState<TinyMCEEditor | undefined>(undefined);
  const claims = useAppSelector((state) => state.claims.claims);
  const { noSubscription, trialSubscription } = useUserMetaData();

  const { allowTracking } = useSettings();

  // sets the base url depending on url parameter
  useEffect(() => {
    const apiUrlParameter = searchParams.get('api');
    if (apiUrlParameter != null) {
      updateAPI(apiUrlParameter, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const genAPIErrMsg = (err: AxiosError) => {
    console.error(err);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp = err.response?.data as any[];
    const types: string[] = resp ? resp['detail'][0]['loc'] : [];
    const endpointName =
      err.request.responseURL.split('/')[
        err.request.responseURL.split('/').length - 1
      ];
    switch (endpointName) {
      case 'claim_detection':
        switch (err.response?.status) {
          case 422:
            if (types.includes('lang')) {
              showError(ErrorMsg.ClaimDetection422Lang);
            } else {
              showError(ErrorMsg.ClaimDetection422);
            }
            break;
          default:
            showError(ErrorMsg.NoServerResp);
        }
        break;
      case 'stance_detection':
        switch (err.response?.status) {
          case 422:
            if (types.includes('lang')) {
              showError(ErrorMsg.StanceDetection422Lang);
            } else {
              showError(ErrorMsg.ClaimDetection422);
            }
            break;
          default:
            showError(ErrorMsg.NoServerResp);
        }
        break;
      default:
        if (err.code === 'ECONNABORTED') {
          showError(ErrorMsg.ServerTimeout);
        } else {
          showError(ErrorMsg.NoServerResp);
        }
    }
  };

  // I see results even trial version has ended
  // I believe it is due to isAuthenticated check

  const checkClaim = (toCheck: Claim, force?: boolean) => {
    if (noSubscription) return;
    if (!force && (toCheck.isBeingChecked || toCheck.hasBeenChecked)) return;
    dispatch(
      updateClaim({
        claim: toCheck.claim,
        isBeingChecked: true,
      })
    );
    dispatch(setNoClaimsFound(false));
    stanceDetectionRequest(toCheck.claim, allowTracking, toCheck.resolved_claim)
      .then((response) => {
        dispatch(
          updateClaim({
            claim: toCheck.claim,
            id: response.data._id,
            evidence: response.data.evidence,
            finalScore: response.data.finalScore,
            // TODO: set this in a finally or catch in case there is an error
            isBeingChecked: false,
            hasBeenChecked: true,
            summary: response.data.summary,
            fix: response.data.fix,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          updateClaim({
            claim: toCheck.claim,
            id: undefined,
            evidence: undefined,
            finalScore: undefined,
            // TODO: set this in a finally or catch in case there is an error
            isBeingChecked: false,
            hasBeenChecked: true,
          })
        );
        genAPIErrMsg(error);
      });
  };

  useEffect(() => {
    const storedClaims = localStorage.getItem('claims');
    if (storedClaims !== null) {
      const parsedClaims = JSON.parse(storedClaims) as Claim[];
      dispatch(setClaims(parsedClaims));
      setTimeout(() => {
        parsedClaims.forEach((claim) => {
          if (claim.isBeingChecked) checkClaim(claim, true);
        });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editor === undefined) return;
    claims.forEach((claim) => {
      if (claim.position === undefined) {
        const position = getPosition(editor, claim.claim);
        dispatch(updateClaim({ claim: claim.claim, position: position }));
      }
      checkClaim(claim);
    });

    clearHighlighting(editor);
    claims.forEach((item) => {
      if (item.finalScore !== undefined) {
        const highlightAs =
          item.finalScore <= 0.4
            ? HighlightAs.Disputed
            : item.finalScore >= 0.6
            ? HighlightAs.Supported
            : HighlightAs.Mixed;
        highlight(editor, item.claim, highlightAs);
      } else {
        highlight(editor, item.claim, HighlightAs.FoundClaim);
      }
    });
    localStorage.setItem('claims', JSON.stringify(claims));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claims, editor]);

  return (
    <>
      {isAuthenticated && noSubscription && !trialSubscription && (
        <TrialInfoMessage />
      )}
      <Grid
        container
        justifyContent="space-between"
        direction={'row'}
        py={4}
        id="content"
      >
        <Grid item xs>
          <Stack direction={'column'}>
            <Login />
            <EditorSide
              allowTracking={allowTracking}
              editor={editor}
              setEditor={setEditor}
            />
          </Stack>
        </Grid>
        <ResultsSide />
      </Grid>
    </>
  );
};

export default HomePage;
