import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack } from '@mui/material';
import axios from 'axios';
import { setSearchValue } from 'core/store/searchSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const SuggestedClaimButtons = () => {
  const dispatch = useDispatch();
  const [suggestedClaims, setSuggestedClaims] = useState<string[]>([]);

  useEffect(() => {
    axios
      .get('https://mock.factiverse.ai/v1/editor')
      .then(function (data) {
        setSuggestedClaims(data.data.suggestedClaims);
      })
      .catch(function (error: string) {
        console.error(error);
      });
  }, []);

  return (
    <Stack
      mt={3}
      direction="row"
      flexWrap="wrap"
      useFlexGap
      spacing={{ xs: 2 }}
      justifyContent="space-between"
    >
      {suggestedClaims?.map((claim, key) => {
        return (
          <Button
            key={key}
            sx={{
              background: 'white',
              textTransform: 'none',
              borderRadius: '30px',
              paddingY: '10px',
              paddingX: '16px',
              color: '#212427',
            }}
            onClick={() => dispatch(setSearchValue(claim))}
            endIcon={<SearchIcon sx={{ pl: 1 }} />}
          >
            {claim}
          </Button>
        );
      })}
    </Stack>
  );
};

export default SuggestedClaimButtons;
