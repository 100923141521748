import { useAuth0 } from '@auth0/auth0-react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Tooltip } from '@mui/material';
import { AxiosError } from 'axios';
import Editor from 'components/Editor';
import { exampleClaims } from 'data/examples/exampleClaims';
import {
  chatGPTEssay,
  elNino,
  healthyEating,
} from 'data/examples/exampleTexts';
import useUserMetaData from 'hooks/useUserMetaData';
import { ErrorMsg } from 'pages/HomePage';
import { useDispatch } from 'react-redux';
import { Editor as TinyMCEEditor } from 'tinymce';
import { claimDetectionRequest } from 'utils/API/api.service';
import { showError, showWarning } from 'utils/SnackbarUtils';
import { YELLOW } from 'utils/theme';
import { calculateChecksum } from 'utils/utils';
import {
  addClaim,
  deleteClaim,
  emptyClaims,
  setIsFindingClaims,
  setNoClaimsFound,
  updateClaim,
} from 'core/store/claimsSlice';
import { useAppSelector } from 'core/store/hooks';
import { setLoginModalOpen } from 'core/store/loginSlice';

const examples: SelectExamples[] = [
  { name: 'What is El Nino', text: elNino, exampleNumber: 1 },
  { name: 'ChatGPT essay', text: chatGPTEssay, exampleNumber: 2 },
  { name: 'Healthy eating', text: healthyEating, exampleNumber: 3 },
];

const EditorSide = ({
  allowTracking,
  editor,
  setEditor,
}: {
  allowTracking: boolean;
  editor: TinyMCEEditor | undefined;
  setEditor: (value: TinyMCEEditor | undefined) => void;
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const claims = useAppSelector((state) => state.claims.claims);
  const { proSubscription } = useUserMetaData();

  const setEditorRef = (editor: TinyMCEEditor) => {
    setEditor(editor);
  };

  const handleEditorChange = () => {
    if (!isAuthenticated) {
      dispatch(setLoginModalOpen());
    }
  };

  const genAPIErrMsg = (err: AxiosError) => {
    console.error(err);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resp = err.response?.data as any[];
    const types: string[] = resp ? resp['detail'][0]['loc'] : [];
    const endpointName =
      err.request.responseURL.split('/')[
        err.request.responseURL.split('/').length - 1
      ];
    switch (endpointName) {
      case 'claim_detection':
        switch (err.response?.status) {
          case 422:
            if (types.includes('lang')) {
              showError(ErrorMsg.ClaimDetection422Lang);
            } else {
              showError(ErrorMsg.ClaimDetection422);
            }
            break;
          default:
            showError(ErrorMsg.NoServerResp);
        }
        break;
      case 'stance_detection':
        switch (err.response?.status) {
          case 422:
            if (types.includes('lang')) {
              showError(ErrorMsg.StanceDetection422Lang);
            } else {
              showError(ErrorMsg.ClaimDetection422);
            }
            break;
          default:
            showError(ErrorMsg.NoServerResp);
        }
        break;
      default:
        if (err.code === 'ECONNABORTED') {
          showError(ErrorMsg.ServerTimeout);
        } else {
          showError(ErrorMsg.NoServerResp);
        }
    }
  };

  const mockClaimLoading = (mockClaims: Claim[]) => {
    mockClaims.map((claim: Claim) => {
      dispatch(addClaim({ claim: claim.claim, isBeingChecked: true }));
    });
    mockClaims.map((claim: Claim) => {
      setTimeout(() => {
        dispatch(
          updateClaim({
            claim: claim.claim,
            id: claim.id,
            evidence: claim.evidence,
            finalScore: claim.finalScore,
            // TODO: set this in a finally or catch in case there is an error
            isBeingChecked: false,
            hasBeenChecked: true,
          })
        );
      }, Math.random() * 10000); // Shows the user it in't instant
    });
  };

  /**
   * Finds claims and adds them to the list of claims.
   *
   *@param {string} within The text within the claims should be found
   */
  const onFindClaims = (within?: string) => {
    if (editor === undefined && within === undefined) return;
    let textToCheck = '';
    if (within === undefined) {
      textToCheck = editor?.getContent({ format: 'text' }) ?? '';
    } else {
      textToCheck = within;
    }
    const sliceAt = proSubscription ? 10000 : 3000;
    if (textToCheck.length > sliceAt) {
      textToCheck = textToCheck.slice(0, sliceAt);
      showWarning(
        `The text you are trying to check is too long. Claim detection beyond ${sliceAt} characters was skipped. You can still fact-check claims manually by selecting a sentence in the text and clicking "Analyze".`
      );
    }
    if (textToCheck.length < 10) {
      showWarning(
        'The text you are trying to check is too short. Claim detection works best with a few sentences or more.'
      );
      return;
    }
    const isSame = examples
      .map((e) => calculateChecksum(e.text, textToCheck))
      .includes(true);

    if (!isSame && isAuthenticated) {
      dispatch(setIsFindingClaims(true));
      // TODO: break up claim detection request into smaller texts if too big #45
      claimDetectionRequest(textToCheck, allowTracking)
        .then((response) => {
          if (response.status !== 200) {
            dispatch(setIsFindingClaims(false));
          }
          if (response.data.detectedClaims.length) {
            response.data.detectedClaims.forEach((item) => {
              dispatch(addClaim({ claim: item.claim }));
            });
          } else {
            dispatch(setNoClaimsFound(true));
          }
        })
        .catch((error: AxiosError) => {
          genAPIErrMsg(error);
        })
        .finally(() => {
          dispatch(setIsFindingClaims(false));
        });
    } else {
      const selectedExample =
        examples.filter((e) => calculateChecksum(e.text, textToCheck))[0] ??
        examples[0];
      const preCheckedClaims = exampleClaims.filter(
        (e) => e.id === selectedExample.exampleNumber
      )[0];
      dispatch(emptyClaims());
      mockClaimLoading(preCheckedClaims.claims);
    }
  };

  const handleClearAndFindClaims = (editor?: TinyMCEEditor) => {
    if (editor === undefined) return;
    const text = editor.getContent({ format: 'text' });
    if (text.length === 0) {
      showWarning('Please add text to the editor');
      return;
    }
    claims.forEach((claim) => {
      if (!text.includes(claim.claim)) {
        dispatch(deleteClaim(claim));
      }
    });
    const selectedText = editor.selection.getContent({ format: 'text' });
    const textToCheck =
      selectedText === '' || selectedText === undefined
        ? editor.getContent({ format: 'text' })
        : selectedText;
    if (textToCheck.length <= 200) {
      dispatch(addClaim({ claim: textToCheck.trim() }));
    } else {
      onFindClaims(textToCheck);
    }
  };

  return (
    <div>
      <Editor
        initialValue={localStorage.getItem('previous-content-draft') ?? elNino}
        onClick={handleEditorChange}
        onKeyDown={handleEditorChange}
        setEditorRef={setEditorRef}
        examples={examples}
        onSelectExample={() => dispatch(emptyClaims())}
        onClearResults={() => dispatch(emptyClaims())}
      />
      <Box pt={2}>
        <Tooltip title="Select a part of the text to fact-check or analyze the whole text.">
          <Button
            id="analyze"
            sx={{
              border: `2px solid ${YELLOW}`,
              borderRadius: '20px',
              color: 'black',
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              background: YELLOW,
              '&:hover': {
                backgroundColor: 'white',
              },
              paddingX: '1em',
              marginLeft: '2em',
              marginBottom: '4em',
            }}
            onClick={() => handleClearAndFindClaims(editor)}
            endIcon={<ArrowForwardIosIcon />}
          >
            Analyze
          </Button>
        </Tooltip>
      </Box>
    </div>
  );
};

export default EditorSide;
