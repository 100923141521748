import { configureStore } from '@reduxjs/toolkit';
import claimsSlice from './claimsSlice';
import loginSlice from './loginSlice';
import searchSlice from './searchSlice';
import sourceFilterSlice from './sourceFilterSlice';
import userSlice from './userSlice';

export const store = configureStore({
  reducer: {
    claims: claimsSlice,
    sourceFilter: sourceFilterSlice,
    login: loginSlice,
    user: userSlice,
    search: searchSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
