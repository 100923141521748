import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AppState {
  searchValue: string;
}

const initialState: AppState = {
  searchValue: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchValue: (state: AppState, action: PayloadAction<string>) => ({
      ...state,
      searchValue: action.payload,
    }),
  },
});

export const { setSearchValue } = searchSlice.actions;
export default searchSlice.reducer;
